import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { ModalProps, SaveButton } from 'src/components/Match/Preference/Modal';
import ModalLayout, { MobileModalType, ModalContent, ModalFooter, ModalHeader, ModalHeaderTitle } from 'src/components/ModalLayout';
import Radio from 'src/components/Radio';
import Switch from 'src/components/Switch';
import useEvent from 'src/hooks/useEvent';
import useGemAmount from 'src/hooks/useGemAmount';
import useUserData from 'src/hooks/useUserData';
import { closeModalAtom, showModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { MatchFilterOption } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import useLocationPreference from './useLocationPreference';
import { getGemLackTriggersFromMatchFiltersAtom, getRequiredGemFromMatchFilterAtom } from 'src/stores/match/atoms';
import GemImage from 'src/components/GemImage';
import GemLackModal from 'src/components/GemLackModal';
import { GEM_LACK_TRIGGER } from 'src/types/gemLack';
import { prioritizeElementInArray } from 'src/utils/array';
const List = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({
  theme
}) => theme.color.gray60__dkGray100};
  ${({
  theme
}) => theme.screenSize.tablet} {
    margin-bottom: 12px;
  }
`;
const TopList = styled(List)`
  ${({
  theme
}) => theme.screenSize.tablet} {
    margin-bottom: 0px;
  }
`;
const Wrap = styled.label<{
  active: boolean;
}>`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
`;
const RowText = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const RowTextTitle = styled.div<{
  active: boolean;
}>`
  font-weight: ${({
  active
}) => active ? '700' : '400'};
  font-size: 16px;
  line-height: 22px;
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
`;
const RowTextDesc = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${({
  theme
}) => theme.color.gray500__dkGray400};
  margin-top: 2px;
`;
const GemIcon = styled(GemImage)`
  width: 18px;
  height: 18px;
`;
const GemGap = styled.div`
  width: 3px;
`;
const ToggleRow = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ToggleGap = styled.div`
  width: 8px;
`;
const GemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const GemRowText = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${({
  theme
}) => theme.color.white};
`;
const SubTitle = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: ${({
  theme
}) => theme.color.white};
`;
type SelectedFilter = {
  filterOption?: MatchFilterOption;
  filterId: string;
};
const LocationPreferenceModal: React.FC<ModalProps> = ({
  onSave,
  onClose,
  landFrom
}) => {
  const {
    t
  } = useTranslation();
  const gemAmount = useGemAmount();
  const showModal = useSetAtom(showModalAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const {
    userProfile
  } = useUserData() || {};
  const pushEvent = useEvent();
  const getRequiredGemFromMatchFilter = useSetAtom(getRequiredGemFromMatchFilterAtom);
  const getGemLackTriggersFromMatchFilters = useSetAtom(getGemLackTriggersFromMatchFiltersAtom);
  const {
    matchFilterSelections,
    setMatchFilterSelections,
    regionMatchFilter,
    regionMatchFilterOption,
    regionMatchFilterSelection,
    countryMatchFilter,
    countryMatchFilterOption,
    countryMatchFilterSelection
  } = useLocationPreference();
  const [nextLocationMatchFilterOption, setNextLocationMatchFilterOption] = useState(regionMatchFilterOption ? {
    filterOption: regionMatchFilterOption,
    filterId: 'REGION_CHOICE'
  } : {
    filterOption: countryMatchFilterOption,
    filterId: 'COUNTRY_GUARANTEE'
  });
  const radio = nextLocationMatchFilterOption.filterOption?.key === 'LOCAL_PAID';
  const handleClose = () => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME[`${landFrom}__LOCATIONFILTER_CLOSE`]
    });
    onClose?.();
  };
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME[`${landFrom}__OPEN_LOCATIONFILTER`]
    });
  }, [pushEvent, landFrom]);
  const getNextMatchFilterSelections = useCallback((selectedFilter: SelectedFilter) => {
    if (!regionMatchFilter || !countryMatchFilter || !selectedFilter.filterOption) return;
    const {
      key: optionKey,
      gemCost: optionGemCost
    } = selectedFilter.filterOption;
    return matchFilterSelections.map(matchFilterSelection => {
      if (matchFilterSelection !== regionMatchFilterSelection && matchFilterSelection !== countryMatchFilterSelection) {
        return matchFilterSelection;
      }
      return {
        ...matchFilterSelection,
        filterId: selectedFilter.filterId,
        optionKey,
        optionGemCost
      };
    });
  }, [countryMatchFilter, countryMatchFilterSelection, matchFilterSelections, regionMatchFilter, regionMatchFilterSelection]);
  if (!regionMatchFilter || !countryMatchFilter || !(regionMatchFilterSelection || countryMatchFilterSelection) || gemAmount === undefined || !userProfile) {
    return null;
  }
  const handleChangeLocation = (matchFilterOption: MatchFilterOption, filterId: string) => {
    const eventName = EVENT_NAME[`${landFrom}__FILTER_${matchFilterOption.key as 'DEFAULT'}`];
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      ...(eventName ? {
        eventName
      } : {
        eventName: EVENT_NAME[`${landFrom}__FILTER_DEFAULT`],
        eventParams: {
          value: matchFilterOption.key
        }
      })
    });
    const {
      gemCost: optionGemCost
    } = matchFilterOption;
    if (optionGemCost === 0) return setNextLocationMatchFilterOption({
      filterOption: matchFilterOption,
      filterId
    });
    const targetMatchFilterSelections = getNextMatchFilterSelections({
      filterOption: matchFilterOption,
      filterId
    });
    const requiredGems = targetMatchFilterSelections ? getRequiredGemFromMatchFilter(targetMatchFilterSelections) : 0;
    const lackingAmount = requiredGems - (gemAmount || 0);
    if (lackingAmount <= 0) {
      return setNextLocationMatchFilterOption({
        filterOption: matchFilterOption,
        filterId
      });
    }
    const gemLackTriggers = getGemLackTriggersFromMatchFilters({
      matchFilterSelections: targetMatchFilterSelections
    });
    showModal({
      key: ModalType.GEM_LACK,
      component: () => <GemLackModal lackingGemAmount={lackingAmount} gemLackTriggers={prioritizeElementInArray(gemLackTriggers, GEM_LACK_TRIGGER.locationFilter)} />
    });
  };
  const save = () => {
    if (!nextLocationMatchFilterOption.filterOption || !regionMatchFilter || !countryMatchFilter) {
      return;
    }
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME[`${landFrom}__LOCATIONFILTER_GOMATCH`]
    });
    const nextMatchFilterSelections = getNextMatchFilterSelections(nextLocationMatchFilterOption);
    if (nextMatchFilterSelections) {
      setMatchFilterSelections(nextMatchFilterSelections);
    }
    closeModal(ModalType.MATCH_SETTING);
  };
  const handleClickSave = () => {
    save();
    onClose?.();
  };
  const handleClickStart = () => {
    save();
    onSave();
  };
  const renderRow = (filterId: string, handler?: () => void) => function RenderRow(filterOption?: MatchFilterOption) {
    if (!filterOption) {
      return null;
    }
    const location = filterOption.key;
    const key = `LocationPreferenceModal-${filterId}-${location}`;
    const active = location === nextLocationMatchFilterOption.filterOption?.key || nextLocationMatchFilterOption.filterOption?.key === 'LOCAL_PAID' && location === 'LOCAL';
    const gemCost = getRequiredGemFromMatchFilter([{
      filterId,
      optionKey: filterOption.key,
      optionGemCost: filterOption.gemCost
    }]);
    return <Wrap key={handler ? undefined : key} htmlFor={key} active={active}>
          <Radio active={active} size={24} />
          <RowText>
            <RowTextTitle active={active}>
              {filterOption.displayName}
            </RowTextTitle>
            {!!handler && <RowTextDesc>
                {radio ? t('GUARANTEE_LOCAL_TITLE') : t('REGION_FREE_LOCAL_SUBTITLE')}
              </RowTextDesc>}
          </RowText>
          {active && (handler ? radio : true) && gemCost !== 0 && <GemRow>
              <GemIcon />
              <GemGap />
              <GemRowText>{gemCost}</GemRowText>
            </GemRow>}
          {!!handler && <>
              <ToggleGap />
              <ToggleRow>
                <Switch checked={radio} onChange={handler} />
                {/* ToggleRow label 버블링 캔슬용 */}
                <input hidden />
              </ToggleRow>
            </>}
          <input id={key} type='radio' value={location} name={`${key}-radio`} hidden checked={active} onChange={() => handleChangeLocation(filterOption, filterId)} />
        </Wrap>;
  };
  const renderToggleRow = (regionRow: [string, MatchFilterOption | undefined], countryRow: [string, MatchFilterOption | undefined]) => {
    const regionRowFilterOption = regionRow[1];
    const countryRowFilterOption = countryRow[1];
    let filterId: string;
    let filterOption: MatchFilterOption | undefined;
    if (nextLocationMatchFilterOption.filterOption?.key && [regionRowFilterOption?.key, countryRowFilterOption?.key].includes(nextLocationMatchFilterOption.filterOption?.key)) {
      filterId = nextLocationMatchFilterOption.filterId;
      filterOption = nextLocationMatchFilterOption.filterOption;
    } else {
      [filterId, filterOption] = regionRow;
    }
    const handler = () => {
      const [nextFilterId, nextRow] = radio ? regionRow : countryRow;
      if (nextRow) {
        handleChangeLocation(nextRow, nextFilterId);
      }
    };
    return renderRow(filterId, handler)(filterOption);
  };
  return <ModalLayout mobileType={MobileModalType.BOTTOM_SHEET} isDim onClose={handleClose} data-testid='Match-LocationPreferenceModal' data-sentry-element="ModalLayout" data-sentry-component="LocationPreferenceModal" data-sentry-source-file="Modal.tsx">
      <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="Modal.tsx">
        <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET} data-sentry-element="ModalHeaderTitle" data-sentry-source-file="Modal.tsx">
          {t('FILTER_LOCATION_TITLE')}
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="Modal.tsx">
        <TopList data-sentry-element="TopList" data-sentry-source-file="Modal.tsx">
          {regionMatchFilter.filterOptions.filter(({
          paid,
          key
        }) => !paid && key !== 'LOCAL').map(renderRow(regionMatchFilter.filterId))}
        </TopList>
        {renderToggleRow([regionMatchFilter.filterId, regionMatchFilter.filterOptions.find(({
        key
      }) => key === 'LOCAL')], [countryMatchFilter.filterId, countryMatchFilter.filterOptions.find(({
        key
      }) => key === 'LOCAL_PAID')])}
        <SubTitle data-sentry-element="SubTitle" data-sentry-source-file="Modal.tsx">{t('REGION_OPTION_DIVISION_LINE')}</SubTitle>
        <List data-sentry-element="List" data-sentry-source-file="Modal.tsx">
          {countryMatchFilter.filterOptions.filter(({
          key
        }) => key !== 'LOCAL_PAID').map(renderRow(countryMatchFilter.filterId))}
        </List>
      </ModalContent>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="Modal.tsx">
        <Button onClick={handleClickStart} id='VIDEOCHAT_MAIN_BUTTON' data-testid='Match-LocationPreferenceModal-start' data-sentry-element="Button" data-sentry-source-file="Modal.tsx">
          {t('VIDEOCHAT_MAIN_BUTTON')}
        </Button>
        <SaveButton id='FILTER_LOCATION_SAVE' onClick={handleClickSave} variant='text' data-sentry-element="SaveButton" data-sentry-source-file="Modal.tsx">
          {t('SAVE')}
        </SaveButton>
      </ModalFooter>
    </ModalLayout>;
};
export default LocationPreferenceModal;